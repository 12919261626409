import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/button/button-icon';
import '@brightspace-ui/core/components/status-indicator/status-indicator';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';
import '@brightspace-ui/core/components/filter/filter.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set.js';
import '@brightspace-ui/core/components/filter/filter-dimension-set-value.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/inputs/input-search.js';
import '@brightspace-ui/core/components/table/table-controls.js';
import '@brightspace-ui/core/components/table/table-col-sort-button.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
// eslint-disable-next-line import/no-unresolved
import '@brightspace-ui/labs/components/pager-numeric.js';

import '../../../shared/components/general/app-link/app-link.js';

import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';

import { bodySmallStyles, labelStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement, nothing } from 'lit';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

class PermissionsTable extends NovaPermissionMixin(LocalizeNova(nav(LitElement))) {
  static get properties() {
    return {
      admins: { type: Array, attribute: false },
      allRoles: { type: Array, attribute: false },
      _processedAdmins: { type: Array, attribute: false },
      _paginatedAdmins: { type: Array, attribute: false },
      _searchQuery: { type: String, attribute: false },
      _sortFacet: { type: String, attribute: false },
      _activeStatus: { type: String, attribute: false },
      _sortDesc: { type: Object, attribute: false },
      _pageNumber: { type: Number, attribute: false },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      tableStyles,
      labelStyles,
      css`
      .filter-and-search-container {
        display: flex;
        margin-bottom: 24px;
        width: 50%;
      }

      .filter-and-search-container .filter-text-container {
        margin-left: 12px;
        margin-right: 2px;
      }

      .filter-and-search-container d2l-input-search {
        flex: 1;
        max-width: 80%;
      }

      .filter-and-search-container d2l-filter {
        flex: 0;
        margin-top: 4px;
        max-width: 0%;
      }

      .no-roles-icon {
        margin-bottom: 2px;
      }

      .no-roles-text {
        margin-left: 4px;
      }


      td.roles-column {
        width: 30%;
      }

      td.emails-column {
        overflow-wrap: break-word;
        word-break: break-word;
      }
`];
  }

  constructor() {
    super();
    this.admins = [];
    this.allRoles = [];
    this.tenantId = '';
    this._processedAdmins = [];
    this._paginatedAdmins = [];
    this._searchQuery = '';
    this._activeStatus = 'active';
    this._sortDesc = false;
    this._sortFacet = 'email';
    this._deleteDialogOpen = false;
    this._pageNumber = 1;
    this._adminsPerPage = 20;

  }

  connectedCallback() {
    super.connectedCallback();
    this.updatePermissions = ['admin:userpermissions:update'];
    this.viewPermissions = ['admin:userpermissions:view'];
    this.deletePermissions = ['admin:userpermissions:delete'];
  }

  updated(_changedProperties) {
    super.updated(_changedProperties);
    if (
      _changedProperties.has('_searchQuery') ||
      _changedProperties.has('_activeStatus') ||
      _changedProperties.has('_pageNumber')
    ) {
      this._processAdmins();
    }

    if (_changedProperties.has('_sortFacet') || _changedProperties.has('_sortDesc')) {
      this._sortAdmins();
    }

    if (_changedProperties.has('admins')) {
      this._processedAdmins = [...this.admins];
      this._processAdmins();
      this._sortAdmins();
    }
  }

  _processAdmins() {
    let processedAdmins = [...this.admins];
    if (this._searchQuery) {
      const query = this._searchQuery.toLowerCase();
      if (query === '') {
        processedAdmins = [...this.admins];
      } else {
        processedAdmins = processedAdmins.filter(admin => {
          const firstName = admin.firstName?.toLowerCase() ?? '';
          const lastName = admin.lastName?.toLowerCase() ?? '';
          const fullName = `${firstName} ${lastName}`;
          return firstName.includes(query) || lastName.includes(query) || fullName.includes(query);
        });
      }
    }

    if (this._activeStatus) {
      processedAdmins = processedAdmins.filter(admin => {
        return this._activeStatus === 'active' ? admin.active : !admin.active;
      });
    }
    this._processedAdmins = [...processedAdmins];
    this._paginateAdmins();
  }

  _sortAdmins() {
    const processedAdmins = [...this._paginatedAdmins];
    this._paginatedAdmins = processedAdmins.sort((a, b) => {
      if (this._sortFacet === 'name') {
        return this._sortDesc ? (b.firstName || '').localeCompare(a.firstName || '') : (a.firstName || '').localeCompare(b.firstName || '');
      } else if (this._sortFacet === 'email') {
        return this._sortDesc ? (b.email || '').localeCompare(a.email || '') : (a.email || '').localeCompare(b.email || '');
      } else if (this._sortFacet === 'title') {
        return this._sortDesc ? (b.title || '').localeCompare(a.title || '') : (a.title || '').localeCompare(b.title || '');
      } else if (this._sortFacet === 'roles') {
        return this._sortDesc ? (b.roles || []).length - (a.roles || []).length : (a.roles || []).length - (b.roles || []).length;
      } else if (this._sortFacet === 'status') {
        return this._sortDesc ? (b.active || 0) - (a.active || 0) : (a.active || 0) - (b.active || 0);
      }
      return 0;
    });
  }

  _paginateAdmins() {
    const paginatedAdmins = [...this._processedAdmins];
    const start = (this._pageNumber - 1) * this._adminsPerPage;
    this._paginatedAdmins = paginatedAdmins.slice(start, start + this._adminsPerPage);
  }

  render() {
    const emptyState = this._paginatedAdmins.length === 0 ?
      html`
        <tr>
          <td colspan="6" class="empty-state-container">
            <div class="empty-state">
              <d2l-empty-state-illustrated illustration-name="desert-road" title-text="${this.localize('edit-permissions.userPermissions.error.noUsers')}" description="${this.localize('edit-permissions.userPermissions.error.description')}">
              </d2l-empty-state-illustrated>
            </div>
          </td>
        </tr>` : nothing;

    return html`
      <div class="filter-and-search-container">
        <d2l-input-search @d2l-input-search-searched="${this._handleSearch}" placeholder="${this.localize('edit-permissions.permissions-table.searchLabel')}..." label="${this.localize('edit-permissions.permissions-table.searchLabel')}"></d2l-input-search>
        <div class="filter-text-container"><p class="d2l-label-text">${this.localize('edit-permissions.permissions-table.filter.introductoryText')}:</p></div>
        <d2l-filter  @d2l-filter-change="${this._handleFilterChange}">
          <d2l-filter-dimension-set ?selection-single=${true} search-type="none" key="status" text="${this.localize('edit-permissions.permissions-table.filter.status')}">
              <d2l-filter-dimension-set-value key="active" text="${this.localize('edit-permissions.permissions-table.filter.active')}" ?selected="${this._activeStatus === 'active'}"></d2l-filter-dimension-set-value>
              <d2l-filter-dimension-set-value key="inactive" text="${this.localize('edit-permissions.permissions-table.filter.inactive')}"  ?selected="${this._activeStatus === 'inactive'}"></d2l-filter-dimension-set-value>
          </d2l-filter-dimension-set>
        </d2l-filter>
      </div>
      <d2l-table-wrapper>
        <table class="d2l-table">
          <thead>
          <tr class="table-header">
            <th><d2l-table-col-sort-button id="name" ?nosort="${this._sortFacet !== 'name'}" ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.name')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="email" ?nosort=${this._sortFacet !== 'email'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.email')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="title" ?nosort=${this._sortFacet !== 'title'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.jobTitle')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="roles" ?nosort=${this._sortFacet !== 'roles'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.permissions')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="status" ?nosort=${this._sortFacet !== 'status'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.status')}</d2l-table-col-sort-button></th>
            <th>${this.localize('edit-permissions.permissions-table.header.action')}</th>
          </tr>
          </thead>
          <tbody>
            ${emptyState}
            ${repeat(this._paginatedAdmins, admin => admin.guid, admin => html`
              <tr>
                <td>${admin?.firstName} ${admin?.lastName}</td>
                <td class="emails-column">${admin?.email}</td>
                <td>${admin?.title}</td>
                <td class="roles-column">
                  ${this._renderRoleList(admin?.roles)}
                </td>
                <td>
                  <d2l-switch text-position="hidden" ?disabled="${!this.canUpdate}" text="${this.localize('manage-user-permissions.userActiveSwitchLabel')}" @change="${this._changeActiveState(admin)}" ?on="${admin?.active}"></d2l-switch>
                </td>
                <td class="action">
                  <d2l-button-icon ?disabled="${!this.canUpdate || !admin.active}" text="${this.localize('edit-permissions.permissions-table.header.editAdmin')}" icon="tier1:edit" @click="${this._editAdminClick(admin)}" ></d2l-button-icon>
                </td>
            </tr>
            `)}
          </tbody>
        </table>
        <d2l-labs-pager-numeric
        id="pagination"
        @d2l-labs-pager-numeric-page-change=${this._handlePageChanged}
        page-number=${this._pageNumber}
        max-page-number=${this._maxPageNumber}></d2l-labs-pager-numeric>
      </d2l-table-wrapper>
    `;
  }

  get _maxPageNumber() {
    return this._processedAdmins?.length > 0 ? Math.ceil(this._processedAdmins?.length / this._adminsPerPage) : 1;
  }

  _renderRoleList(roles) {
    if (!roles?.length) {
      return html `<d2l-icon icon="tier1:alert" class="no-roles-icon"></d2l-icon> <span class="d2l-body-small no-roles-text">${this.localize('edit-permissions.permissions-table.header.noRoles')}</span>`;
    } else {
      return html `
        <d2l-tag-list>
          ${repeat(roles, role => role.roleId, role => { const roleName = this.allRoles.find(r => r.roleId === role.roleId)?.roleName; return html`
            <d2l-tag-list-item text="${roleName}"></d2l-tag-list-item>
          `;})}
        </d2l-tag-list>
      `;
    }
  }

  _changeActiveState(admin) {
    return e => {
      e.target.on = admin.active;
      this.dispatchEvent(new CustomEvent('nova-admin-state-change', {
        detail: { admin, action: 'toggle-active-status' },
        bubbles: true,
        composed: true,
      }));
    };
  }

  _editAdminClick(admin) {
    return () => {
      this.dispatchEvent(new CustomEvent('nova-admin-state-change', {
        detail: { admin, action: 'manage-user-permissions' },
        bubbles: true,
        composed: true,
      }));
    };
  }

  _handlePageChanged(e) {
    this._pageNumber = e.detail.page;
  }

  _handleSearch(e) {
    this._searchQuery = e.detail.value;
    this._pageNumber = 1;
  }

  _handleSort(e) {
    const desc = e.target.hasAttribute('desc');
    this._sortFacet = e.target.id;
    this._sortDesc = !desc;
  }

  _handleFilterChange(e) {
    if (e.detail.dimensions[0].cleared) {
      this._activeStatus = '';
    } else {
      this._activeStatus = e.detail.dimensions[0].changes[0].valueKey;
    }
    this._pageNumber = 1;
  }

}

window.customElements.define('permissions-table', PermissionsTable);
