import '@brightspace-ui/core/components/dialog/dialog.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/paging/pager-load-more.js';
import '@brightspace-ui/core/components/inputs/input-date-time-range.js';

import { css, html, LitElement } from 'lit';
import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { PageableMixin } from '@brightspace-ui/core/components/paging/pageable-mixin.js';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { getStartOfDay, subtractDays } from '../../../../../shared/helpers/dateTime.js';
import { AUDIT_OPTIONS } from '../../../../../shared/constants.js';
import { AuditSchema } from '../../../../../shared/models/schema/audit.js';
import { LocalizeNova } from '../../../../shared/mixins/localize-nova/localize-nova.js';
import { novaTableStyles } from '../../../../main/components/applications/application-table/styles.js';
import { optionsTemplate } from '../../../../shared/helpers/generic-templates/generic-templates.js';

import '../../../../shared/components/general/nova-info-copy/nova-info-copy.js';

export default class AuditTable extends LocalizeNova(SkeletonMixin(RequesterMixin(PageableMixin(LitElement)))) {

  static get properties() {
    return {
      params: { type: Object },
      query: { type: Object },
      tenant: { type: String },
      _audit: { type: Object, attribute: false },
      _displayNames: { type: Object, attribute: false },
      _search: { type: Array, attribute: false },
      _auditItems: { type: Array, attribute: false },
      _auditPerPage: { type: Number, attribute: false },
      _selectedAudit: { type: Object, attribute: false },
      _tenantType: { type: String, attribute: false },
      _timeframe: { type: String, attribute: false },
      _customStart: { type: String, attribute: false },
      _customEnd: { type: String, attribute: false },
      _timeframes: { type: Array, attribute: false },
      _error: { type: String, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      novaTableStyles,
      selectStyles,
      heading2Styles,
      css`
        .submit-buttons {
          padding-top: 10px;
        }

        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        .action-filter {
          margin-bottom: 10px;
        }

        .message {
          height: 200px;
        }

        .nova-table {
          padding-bottom: 8px;
        }

        .table-row, .table-header {
          grid-template-columns: repeat(4, 1fr);
        }

        .table-span {
          cursor: auto;
          grid-column: span 4;
          text-align: center;
        }

        .initiator, .subject {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 250px;
        }
`,
    ];
  }

  constructor() {
    super();
    const { allowedTimeframes } = AUDIT_OPTIONS;

    this._auditPerPage = 10;
    this._timeframe = 'lastDay';
    this._search = '';
    this._timeframes = allowedTimeframes;
    this._customStart = null;
    this._customEnd = null;
    this._error = null;
    this._displayNames = {};
  }

  get auditDialog() {
    this._auditDialog = this._auditDialog || this.shadowRoot.getElementById('audit-dialog');
    return this._auditDialog;
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    const tenantObject = await this.client.fetchTenant(this.tenant);
    this._tenantType = tenantObject.type;
  }

  get auditActions() {
    return AuditSchema.getFilteredActions(this._tenantType);
  }

  render() {
    if (!this._auditItems) return html`Loading`;
    return html`
      <h2 class="d2l-heading-2">${this.localize('manage-audit.title')}</h2>
      <select id="timeframe-select-filter" class='action-filter d2l-input-select' .value=${this._timeframe} @change=${this._timeframeChange}>
        ${repeat(this._timeframes, tf => tf.tf, tf => html`
          <option .selected="${this._timeframe === tf.value}" .value=${tf.value}>${tf.displayName}</option>
        `)}
      </select>
      <select id="action-select-filter" class='action-filter d2l-input-select' .value=${this._actionFilter} @change=${this._actionChange}>
        <option value="" selected>Filter by action</option>
        ${optionsTemplate(this.auditActions)}
      </select>
      ${this._timeframe === 'custom' ? html`
        <div class="action-filter">
          <d2l-input-date-time-range
            label="Audit Date Range"
            start-value="${this._customStart}"
            end-value="${this._customEnd}"
            min-value="${new Date(AUDIT_OPTIONS.minimumAuditDate).toISOString()}"
            max-value="${this._getMaximumValidDate()}"
            @change=${this._customTimeframeChange}
            ?inclusive-date-range=${true}
            start-label="From"
            end-label="To"
            label-hidden
          ></d2l-input-date-time-range>
        </div>
      ` : ''}
      <d2l-input-text @change=${this._handleSearchChange} id="search" label="Search"></d2l-input-text>
      <div class="nova-table" role="table">
        <div class="table-header d2l-skeletize" role="row">
          <div class="table-item date" role="columnheader">Date</div>
          <div class="table-item action" role="columnheader">Action</div>
          <div class="table-item initiator" role="columnheader">Initiator</div>
          <div class="table-item subject" role="columnheader">Subject</div>
        </div>
        ${this._error ? html`
          <div class="table-row d2l-skeletize" role="row">
            <div class="table-item table-span">
              ${this._error}
            </div>
          </div>
        ` : html`
          ${this._auditItems.length === 0 ? html`
            <div class="table-row d2l-skeletize" role="row">
              <div class="table-item table-span">
                No matching audit records found
              </div>
            </div>
          ` : html`
            ${this._auditItems.map(a => html`
              <div class="table-row d2l-skeletize" role="row" @click=${this._clickAuditRow(a)}>
                <div class="table-item date" role="cell">${a.formattedDate}</div>
                <div class="table-item action" role="cell">${a.getTranslatedValue('action')}</div>
                <div class="table-item initiator" role="cell">${this._uuidTemplate(a, 'initiator')}</div>
                <div class="table-item subject" role="cell">${this._uuidTemplate(a, 'subject')}</div>
              </div>
            `)}
          `}
        `}
      </div>
      ${this._auditItems.length > 0 ? html`
        <d2l-pager-load-more
          ?has-more=${this._audit.nextToken}
          page-size=${this._auditPerPage}
          @d2l-pager-load-more=${this._loadMore}
          >
        </d2l-pager-load-more>
        ${this._auditDialogTemplate()}
      ` : ''}
    `;
  }

  _uuidTemplate(record, attribute) {
    const uuid = record[attribute];
    const id = `${record.action}_${record.date}_${attribute}`;
    return html`
        <div>
          <nova-info-copy id="${id}"
                          .info="${uuid}"
                          .infoAlt="${this._displayNames[uuid]}"
                          @nova-ia-info-clicked="${this._getDisplayName(uuid)}"
                          @nova-ia-action-clicked="${this.copyToClipboard(id)}"
                          ></nova-info-copy>
        </div>`;
  }

  copyToClipboard(id) {
    return () => {
      this.shadowRoot.getElementById(id).copyToClipboard();
    };
  }

  _getDisplayName(uuid) {
    return async() => {
      if (!this._displayNames[uuid]) {
        const { type, displayName } = await this.client.getDisplayName(uuid);
        if (type === 'not_found') {
          this._displayNames[uuid] = displayName;
          this.session.toast({ type: 'info', message: 'Cannot find item with that uuid.' });
        } else {
          this._displayNames[uuid] = displayName;
        }
        this.requestUpdate();
      }
    };
  }

  async updated(_changedProperties) {
    for (const [propName, oldValue] of _changedProperties) {
      if (propName === 'tenant' && this.tenant && oldValue !== this.tenant) {
        // eslint-disable-next-line no-await-in-loop
        await this._updateTable();
      }
    }
  }

  _actionChange(e) {
    this._actionFilter = e.target.value;
    this._updateTable();
  }

  _auditDialogTemplate() {
    const audit = this._selectedAudit || {};
    return html`
      <d2l-dialog id="audit-dialog" title-text="Audit - ${audit.action} - ${audit.formattedDate}">
        <div class="dialog-attributes">
          <pre>${JSON.stringify(audit.data, undefined, 2)}</pre>
        </div>
      </d2l-dialog>
    `;
  }

  _clickAuditRow(audit) {
    return e => {
      this._openDialog(e, audit);
    };
  }

  _handleSearchChange(e) {
    this._search = e.target?.value;
    this._updateTable();
  }

  _openDialog(e, audit) {
    this._selectedAudit = audit;
    this.auditDialog.opened = true;
  }

  async _loadMore(e) {
    const auditResponse = await this.client.getAudit(
      this.tenant,
      this._auditPerPage,
      this._actionFilter,
      this._timeframe,
      this._search,
      this._customStart,
      this._customEnd,
      this._audit.nextToken
    );
    this._audit.items.push(...auditResponse.items);
    this._audit.nextToken = auditResponse.nextToken;

    this._auditItems = this._audit?.items ? [...this._audit.items] : [];
    e.detail.complete();
  }

  // Needed for the PageableMixin
  _getItemByIndex(index) {
    return this.renderRoot.getElementById(`audit-row-${index}`);
  }

  // Needed for the PageableMixin
  _getItemShowingCount() {
    return this._auditItems?.length;
  }

  _timeframeChange(e) {
    this._timeframe = e.target.value;

    if (this._timeframe !== 'custom') {
      this._customStart = null;
      this._customEnd = null;
      this._error = null;
    } else {
      this._customEnd = this._getMaximumValidDate();
      this._customStart = this._getMinimumDateForCurrentEndDate();
    }

    this._updateTable();
  }

  _customTimeframeChange(e) {
    let updateTable = false;

    if (e.target.startValue !== this._customStart) {
      this._customStart = e.target.startValue;
      updateTable = true;
    }

    if (e.target.endValue !== this._customEnd) {
      this._customEnd = e.target.endValue;
      updateTable = true;
    }

    if (updateTable && this._validateCustomTimeframe()) {
      this._updateTable();
    }
  }

  _validateCustomTimeframe() {
    if (this._timeframe !== 'custom') {
      this._error = null;
    } else if (!this._customStart || !this._customEnd) {
      this._error = 'You must specify a valid start and end date';
    } else if (new Date(this._customStart) > new Date(this._customEnd)) {
      this._error = 'The start date must be before the end date';
    } else {
      this._error = new Date(getStartOfDay(this._customStart)) < new Date(getStartOfDay(subtractDays(this._customEnd, AUDIT_OPTIONS.maximumAuditRangeDays)))
        ? `The selected date range must be less than or equal to ${AUDIT_OPTIONS.maximumAuditRangeDays} days. Please refine your search and try again.`
        : null;
    }

    return this._error === null;
  }

  _getMaximumValidDate() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).toISOString();
  }

  _getMinimumDateForCurrentEndDate() {
    let date = new Date(AUDIT_OPTIONS.minimumAuditDate).toISOString();

    if (this._customEnd) {
      date = subtractDays(this._customEnd, AUDIT_OPTIONS.maximumAuditRangeDays);
    }

    return getStartOfDay(date);
  }

  async _updateTable() {
    try {
      this.skeleton = true;
      this._audit = await this.client.getAudit(
        this.tenant,
        this._auditPerPage,
        this._actionFilter,
        this._timeframe,
        this._search,
        this._customStart,
        this._customEnd
      );
      this._error = null;
    } catch (e) {
      this._audit = { items: [] };

      if (e.status === 413) {
        this._error = 'The selected timeframe returns too much data. Please refine your search and try again';
      } else {
        this._error = 'There was an error fetching audit records. Please try again';
      }
    }

    this.skeleton = false;

    this._auditItems = this._audit?.items ? [...this._audit.items] : [];
  }
}

window.customElements.define('audit-table', AuditTable);
