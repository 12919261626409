import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/selection/selection-action.js';
import '@brightspace-ui/core/components/selection/selection-input.js';
import '@brightspace-ui/core/components/selection/selection-select-all.js';
import '@brightspace-ui/core/components/table/table-controls.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { html, LitElement, nothing } from 'lit';

import { heading2Styles } from '@brightspace-ui/core/components/typography/styles.js';

import '../../../shared/components/general/no-results/no-results.js';
import { CATALOG_PACKAGE_MAP, CATALOG_PACKAGES, getStreamsNotInPackage } from '../../../../shared/helpers/package-manager/packageDefinitions.js';
import { ALL_STATIC_STREAMS } from '../../../../shared/helpers/package-manager/packageStreamDefinitions.js';
import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';
import { NovaPermissionMixin } from '../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';
import Tenant from '../../../../shared/models/tenant/index.js';

export default class SkillStreamManager extends NovaPermissionMixin(SkeletonMixin(LocalizeNova(RequesterMixin(LitElement)))) {

  static get properties() {
    return {
      /**
       * Current tenant whose visibility settings are being managed
       */
      contextTenant: { type: Object },
      /**
       * Array of visible skills for this tenant
       */
      _visibleSkills: { type: Array, attribute: false },
      /**
       * Flag to check whether visible skills are available or not
      */
      _visibilityFetched: { type: Boolean },
      /**
       * The currently selected package
       */
      _selectedPackage: { type: Object, state: true },
    };
  }

  static get styles() {
    return [
      super.styles,
      inputLabelStyles,
      selectStyles,
      tableStyles,
      heading2Styles,
    ];
  }

  constructor() {
    super();
    this._visibleStreams = [];
    this._visibilityFetched = false;
    this._otherStreams = [];
    this.contextTenant = new Tenant();
  }

  async connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
    this.session = this.requestInstance('d2l-nova-session');
    this._getVisibleStreams();
    const packageName = this._isDeprecated ? 'new-general' : this.contextTenant.package;
    this.setSelectedPackage(packageName);
  }

  get _isDeprecated() {
    return !this.contextTenant?.package || this.contextTenant.package === 'general';
  }

  setSelectedPackage(packageName) {
    // If there is a selected package, remove its streams from the visible streams list
    if (this._selectedPackage) {
      this._visibleStreams = this._visibleStreams.filter(s => !this._selectedPackage.streams.some(s2 => s2.id === s));
    }
    // Set the new selected package
    this._selectedPackage = { ...CATALOG_PACKAGE_MAP[packageName] };
    // Add the streams from the new package to the visible streams list
    this._visibleStreams.push(...this._selectedPackage.streams.map(s => s.id));
    // Set the list of other streams to every stream not in this package
    this._otherStreams = getStreamsNotInPackage(this._selectedPackage?.id);
    this.contextTenant.package = packageName;
  }

  render() {
    return html`
      <h2 class="d2l-heading-2">${this.localize('manage-enabled-streams.title')}</h2>
      ${this._packageSelectorTemplate()}
      <h3 class="d2l-heading-3">${this.localize(this._selectedPackage.langTerm)} streams</h3>
      ${this._streamTableTemplate(this._selectedPackage?.streams, 'empty-message')}

      ${!this._otherStreams?.length ? nothing : html`
        <h3 class="d2l-heading-3">Other streams</h3>
        ${this._streamTableTemplate(this._otherStreams, 'empty-message')}
      `}
      <d2l-button @click=${this._saveVisibilitySettings}>Save</d2l-button>
    `;
  }

  _getVisibleStreams() {
    // check _visibleStreams to see if the ids exist in ALL_STATIC_STREAMS and filter out deprecated streams
    const uniqueStreamSet = new Set(ALL_STATIC_STREAMS.map(({ id }) => id));

    Promise.resolve(this.client.getVisibleSkills(this.contextTenant.id))
      .then(result => {
        if (result) {
          this._visibleStreams = result.map(va => va.subjectId).filter(id => uniqueStreamSet.has(id));
          const requiresUpdate = this.contextTenant?.package === 'new-general' && this._visibleStreams.length === 0 && !this.contextTenant?.hasTag('automaticCatalogOptOut');

          // update deprecated package names and populate empty default streams
          if (this._isDeprecated || requiresUpdate) {
            this._visibleStreams = this._visibleStreams.length
              ? this._visibleStreams
              : CATALOG_PACKAGE_MAP['new-general'].streams.map(({ id }) => id);
            this.client.updateTenantPackage(this.contextTenant.id, 'new-general', this._visibleStreams);
          }

          this._visibilityFetched = true;
        }
      });
  }

  _isStreamVisible(id) {
    return this._visibleStreams.includes(id);
  }

  _changePackage(e) {
    this.setSelectedPackage(e.target.value);
  }

  _packageSelectorTemplate() {
    return html`
        <label class="d2l-input-label" for="category">Package</label>
        <select
          id="package"
          name="package"
          class="d2l-input-select"
          .value="${this._selectedPackage.id}"
          @change=${this._changePackage}
          aria-label="Package">
          ${CATALOG_PACKAGES.map(p => html`<option .selected="${this._selectedPackage.id === p.id}" value=${p.id}>${this.localize(p.langTerm)}</option>`)}
        </select>`;
  }

  _streamTableTemplate(streams, noResultsMessage) {
    if (this.skeleton) return html`<div class="d2l-skeletize table-skeleton"></div>`;
    if (!streams || streams.length === 0) return html`<no-results>${noResultsMessage}</no-results>`;
    return html`
      <d2l-table-wrapper sticky-headers>
        <table class="d2l-table">
          <thead>
          <tr>
            <th><d2l-selection-select-all></d2l-selection-select-all></th>
            <th>Stream</th>
          </tr>
          </thead>
          <tbody>
          ${streams.map(stream => this._streamRowTemplate(stream))}
          </tbody>
        </table>
      </d2l-table-wrapper>
    `;
  }

  _streamRowTemplate(stream) {
    return html`
      <tr>
        <td>
          <d2l-selection-input
            key="${stream.id}"
            label="${this.localize(stream.langTerm)}"
            ?selected="${this._isStreamVisible(stream.id)}"
            @d2l-selection-change="${this._visibilityChanged}"></d2l-selection-input>
        </td>
        <td>${this.localize(stream.langTerm)}</td>
      </tr>`;
  }

  _saveVisibilitySettings() {
    this.skeleton = true;
    this.client.updateTenantPackage(this.contextTenant.id, this._selectedPackage.id, this._visibleStreams);
    this.session.toast({ type: 'success', message: 'Successfully updated stream visibility' });
    this.skeleton = false;
  }

  _visibilityChanged(e) {
    this.skeleton = true;
    const id = e.target.key;
    const visible = e.target.selected;
    this._visibleStreams = visible ? [...this._visibleStreams, id] : this._visibleStreams.filter(s => s !== id);
    this.skeleton = false;
  }
}

window.customElements.define('skill-stream-manager', SkillStreamManager);
